import { Grid, Typography } from '@mui/material'

import { makeURLAccessLegacy, updateErrorMessage } from 'services/helpers'
import { useSnack } from 'services/hooks'
import { Snackbar } from 'components/molecules'
import FEEDBACK_SNACK from 'feedBackSnack'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import * as Styled from './style'
import { CARD_TYPE } from '../constants'
import {
  openFrequencyDiaryPDF,
  openIndividualFormPDF,
  openNoteDiaryPDF,
  openSchoolReportPDF,
  openSchoolReportXLSX,
  openContentDiaryPDF
} from '../../../../services'
import { useClassDetailsContext } from '../../../../context'

const Card = ({ className, item, handleClose }) => {
  const isBlank = true
  const { snack, setSnack, snackOpen } = useSnack()
  const { setLoading } = useLoading(false)
  const { classDetails } = useClassDetailsContext()

  const handleError =
    (feedbackMessage) =>
    ({ error, status }) => {
      setLoading(false)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage
      })
    }

  const handleAction = (itemData) => {
    const actionMap = {
      [CARD_TYPE.FREQUENCY_DIARY]: () => {
        setLoading(true)
        openFrequencyDiaryPDF({
          classDisciplineId: itemData.classDisciplineId,
          classId: itemData.classId,
          onError: handleError(
            FEEDBACK_SNACK.noAccessPermissionToPartialReport
          ),
          onFinish: () => setLoading(false)
        })
      },
      [CARD_TYPE.NOTE_DIARY]: () => {
        setLoading(true)
        openNoteDiaryPDF({
          classDisciplineId: itemData.classDisciplineId,
          classId: itemData.classId,
          openURlLegacy: () => makeURLAccessLegacy(itemData.path, isBlank),
          onError: handleError(FEEDBACK_SNACK.noAccessPermissionToSchoolReport),
          onFinish: () => setLoading(false)
        })
      },
      [CARD_TYPE.SCHOOL_REPORT_PDF]: () => {
        setLoading(true)
        openSchoolReportPDF({
          classId: itemData.classId,
          openURlLegacy: () => makeURLAccessLegacy(itemData.path, isBlank),
          onError: handleError(FEEDBACK_SNACK.noAccessPermissionToSchoolReport),
          onFinish: () => setLoading(false)
        })
      },
      [CARD_TYPE.SCHOOL_REPORT_XLSX]: () => {
        setLoading(true)
        openSchoolReportXLSX({
          classId: itemData.classId,
          openURlLegacy: () => makeURLAccessLegacy(itemData.path, isBlank),
          onError: handleError(FEEDBACK_SNACK.noAccessPermissionToSchoolReport),
          onFinish: () => setLoading(false)
        })
      },
      [CARD_TYPE.CONTENT_DIARY]: () => {
        setLoading(true)

        openContentDiaryPDF({
          classId: itemData.classId,
          classDisciplineId: itemData.classDisciplineId,
          onError: handleError(FEEDBACK_SNACK.noAccessPermissionToSchoolReport),
          onFinish: () => setLoading(false)
        })
      },
      [CARD_TYPE.INDIVIDUAL_FORM_PDF]: () => {
        setLoading(true)
        openIndividualFormPDF({
          classId: itemData.classId,
          unit: classDetails.unit,
          course: classDetails.course,
          level: classDetails.level,
          openURlLegacy: () => makeURLAccessLegacy(itemData.path, isBlank),
          onError: handleError(FEEDBACK_SNACK.noAccessPermissionToSchoolReport),
          onFinish: () => setLoading(false)
        })
      }
    }

    const action = actionMap[itemData.label]

    return action ? action() : makeURLAccessLegacy(itemData.path, isBlank)
  }

  return (
    <>
      <Grid item xs={4}>
        <Styled.Card
          className={className}
          sx={{ minWidth: 150 }}
          onClick={() => {
            if (item.isModalCards) {
              item.handleClick()
              return handleClose(true)
            }

            if (item.isModalCoverPage) {
              item.handleClick()
              return handleClose(true)
            }

            return handleAction(item)
          }}
        >
          <Styled.CardContent>
            <Styled.PictureAsPdf sx={{ fontSize: 40 }} />
            <Typography
              sx={{ fontSize: 12 }}
              color="text.secondary"
              gutterBottom
            >
              {item.label}
            </Typography>
          </Styled.CardContent>
        </Styled.Card>
      </Grid>
      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </>
  )
}

export { Card }
