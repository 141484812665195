import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { cleanupHtmlEntities } from 'services/helpers'
import { UNKNOWN_DESCRIBE } from '../constants'

const {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  createEmptyCells
} = utilsPDF

export default function buildSecondaryHeader(student, courseInfo) {
  const marginBetwenLabels = [0, 0, 0, 2]

  return [
    {
      table: {
        body: [
          [
            {
              colSpan: 6,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Nome',
                  bold: true
                },
                {
                  text: student.name
                }
              ]
            },
            ...createEmptyCells(5),
            {
              colSpan: 6,
              border: noBorderOnTop,
              stack: [
                {
                  text: 'Filiação',
                  bold: true
                },
                {
                  text:
                    student.mother_name ??
                    student.father_name ??
                    UNKNOWN_DESCRIBE
                }
              ]
            },
            ...createEmptyCells(5)
          ],
          [
            {
              colSpan: 4,
              border: noBorderOnTop,
              stack: [
                {
                  columnGap: -10,
                  margin: marginBetwenLabels,
                  columns: [
                    {
                      text: 'Data de Nascimento',
                      bold: true
                    },
                    {
                      text: student.birth_date
                    }
                  ]
                },
                {
                  columnGap: -10,
                  columns: [
                    {
                      text: 'Sexo',
                      bold: true
                    },
                    {
                      text: student.sex === 'M' ? 'Masculino' : 'Feminino',
                      margin: [-58, 0, 0, 0]
                    }
                  ]
                }
              ]
            },
            ...createEmptyCells(3),
            {
              colSpan: 4,
              border: noBorderOnTop,
              stack: [
                {
                  columnGap: -50,
                  margin: marginBetwenLabels,
                  columns: [
                    {
                      text: 'Nacionalidade',
                      bold: true
                    },
                    {
                      text: student.nationality ?? UNKNOWN_DESCRIBE
                    }
                  ]
                },
                {
                  columnGap: -50,
                  columns: [
                    {
                      text: 'Naturalidade',
                      bold: true
                    },
                    {
                      text: student.place_of_birth ?? UNKNOWN_DESCRIBE,
                      margin: [-6, 0, 0, 0]
                    }
                  ]
                }
              ]
            },
            ...createEmptyCells(3),
            {
              colSpan: 4,
              border: noBorderOnTop,
              stack: [
                {
                  columnGap: -125,
                  margin: marginBetwenLabels,
                  columns: [
                    {
                      text: 'CPF',
                      bold: true
                    },
                    {
                      text: student.cpf ?? UNKNOWN_DESCRIBE
                    }
                  ]
                },
                {
                  columnGap: -125,
                  columns: [
                    {
                      text: 'RG',
                      bold: true
                    },
                    {
                      text: student.rg ?? UNKNOWN_DESCRIBE,
                      margin: [-6, 0, 0, 0]
                    }
                  ]
                }
              ]
            },
            ...createEmptyCells(3)
          ],
          [
            {
              colSpan: 12,
              stack: [
                {
                  text: courseInfo.course,
                  alignment: 'center',
                  bold: true
                },
                {
                  text: [
                    'Autorização: ',
                    courseInfo.resolutionCourse
                      ? cleanupHtmlEntities(courseInfo.resolutionCourse)
                      : 'Não informado.'
                  ],
                  alignment: 'center'
                }
              ]
            },
            ...createEmptyCells(11)
          ]
        ],
        widths: '*'
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    }
  ]
}
