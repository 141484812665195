import { accessObjectByString } from 'services/helpers'
import theme from 'theme/designTokens'
import { utilsPDF } from '../utils'

export default function buildTable({
  data,
  columns,
  tableStyle,
  columnStyle,
  rowStyle
}) {
  const borderColor = Array(5).fill(theme.palette.gray.bgLight)

  const tableColumns = []
  const tableRows = []
  const tableWidths = columnStyle?.widths ?? []
  const tableHeights = [columnStyle.height ?? '*']

  columns.forEach((column) => {
    tableColumns.push({
      text: column.label,
      bold: true,
      fillColor: theme.palette.background.tableHeaderPDF,
      borderColor,
      ...columnStyle,
      ...column.columnStyle
    })
  })

  data.forEach((item, rowIndex) => {
    let rowHeight = '*'

    if (rowStyle?.heights) {
      rowHeight = rowStyle.heights[rowIndex]
    } else if (rowStyle?.height) {
      rowHeight = rowStyle.height
    }

    tableHeights.push(rowHeight)

    columns.forEach((column) => {
      const label = accessObjectByString(item, column.rowLabelKey)
      let rowLabel = label

      if (column.rowLabelFormatter) {
        rowLabel = column.rowLabelFormatter(label, item, rowIndex)
      }

      if (!tableRows[rowIndex]) {
        tableRows[rowIndex] = []
      }

      let newRowStyle = column.rowStyle

      if (column.rowStyle instanceof Function) {
        newRowStyle = column.rowStyle(label, item)
      }

      tableRows[rowIndex].push({
        text: rowLabel,
        borderColor,
        ...rowStyle,
        ...newRowStyle
      })
    })
  })

  const getPadding = (paddingIndex) => (index) => {
    const columnPaddingDoesNotExists = !columnStyle?.padding?.[index]
    const rowPaddingDoesNotExists = !rowStyle?.padding?.[index]

    if (columnPaddingDoesNotExists && rowPaddingDoesNotExists) return 5

    if (!columnPaddingDoesNotExists && index === 0)
      return columnStyle.padding[paddingIndex]

    return rowStyle.padding[paddingIndex]
  }

  return {
    fontSize: 7,
    table: {
      widths: tableWidths,
      heights: tableHeights,
      body: [tableColumns, ...tableRows]
    },
    layout: {
      paddingLeft: getPadding(0),
      paddingTop: getPadding(1),
      paddingRight: getPadding(2),
      paddingBottom: getPadding(3),
      ...utilsPDF.borderLayoutDefault
    },
    ...tableStyle
  }
}
